ul#banners_grid {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}
ul#banners_grid li {
  position: relative;
  width: 33.33%;
  float: left;
  border-top: 5px solid #fff;
  border-right: 5px solid #fff;
}
ul#banners_grid li:last-child {
  border-right: none;
}
@media (max-width: 767px) {
  ul#banners_grid li {
    width: 100%;
    float: none;
    border-right: none;
  }
}
ul#banners_grid li a.img_container {
  position: relative;
  overflow: hidden;
  z-index: 0;
  display: block;
  width: 100%;
  height: 250px;
}
@media (max-width: 767px) {
  ul#banners_grid li a.img_container {
    width: 100%;
    float: none;
    height: 210px;
  }
}
ul#banners_grid li a.img_container img {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 100%;
  max-width: 100%;
  height: auto;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media (max-width: 1199px) {
  ul#banners_grid li a.img_container img {
    height: 100%;
    width: auto;
    max-width: inherit;
  }
}
@media (max-width: 767px) {
  ul#banners_grid li a.img_container img {
    width: 100%;
    height: auto;
  }
}
ul#banners_grid li a.img_container:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1.02);
  -moz-transform: translate(-50%, -50%) scale(1.02);
  -ms-transform: translate(-50%, -50%) scale(1.02);
  -o-transform: translate(-50%, -50%) scale(1.02);
  transform: translate(-50%, -50%) scale(1.02);
}
ul#banners_grid li a.img_container .short_info {
  position: absolute;
  left: 0;
  top: 0%;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  padding: 0 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
  text-align: center;
}
ul#banners_grid li a.img_container .short_info h3 {
  color: #fff;
  font-size: 24px;
  font-size: 1.5rem;
  margin: 0;
  padding: 0;
  font-weight: 400;
  text-transform: uppercase;
  font-weight: 900;
}
@media (max-width: 767px) {
  ul#banners_grid li a.img_container .short_info h3 {
    margin-top: -20px;
  }
}
ul#banners_grid li a.img_container .short_info div {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(-25px);
  -moz-transform: translateY(-25px);
  -ms-transform: translateY(-25px);
  -o-transform: translateY(-25px);
  transform: translateY(-25px);
  text-align: center;
  width: 100%;
  visibility: hidden;
  display: table;
  opacity: 0;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
ul#banners_grid li a.img_container .short_info div .btn_1 {
  padding: 10px 22px;
  font-size: 13px;
  font-size: 0.8125rem;
}
@media (max-width: 767px) {
  ul#banners_grid li a.img_container .short_info div {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);
    -o-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}
ul#banners_grid li a.img_container .short_info:hover div {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -ms-transform: translateY(-50px);
  -o-transform: translateY(-50px);
  transform: translateY(-50px);
}

.categories_grid a {
  display: block;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 767px) {
  .categories_grid a {
    height: 180px;
    margin-bottom: 10px;
  }
}
.categories_grid a .wrapper {
  width: 100%;
  background: transparent;
  background: -webkit-linear-gradient(top, transparent, #000);
  background: linear-gradient(to bottom, transparent, #000);
  position: absolute;
  left: 0;
  bottom: 0;
  line-height: 1;
  padding: 100px 20px 20px 20px;
}
.categories_grid a .wrapper h2 {
  color: #fff;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 21px;
  font-size: 1.3125rem;
  margin: 0;
  -webkit-transform: translateY(20px) translateZ(0);
  -moz-transform: translateY(20px) translateZ(0);
  -ms-transform: translateY(20px) translateZ(0);
  -o-transform: translateY(20px) translateZ(0);
  transform: translateY(20px) translateZ(0);
  -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
.categories_grid a .wrapper p {
  color: #fff;
  opacity: 0;
  margin: 0;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 500;
  -webkit-transform: translateY(20px) translateZ(0);
  -moz-transform: translateY(20px) translateZ(0);
  -ms-transform: translateY(20px) translateZ(0);
  -o-transform: translateY(20px) translateZ(0);
  transform: translateY(20px) translateZ(0);
  -webkit-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
.categories_grid a img {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}
.categories_grid a:hover img {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.categories_grid a:hover h2 {
  -webkit-transform: translateY(-5px) translateZ(0);
  -moz-transform: translateY(-5px) translateZ(0);
  -ms-transform: translateY(-5px) translateZ(0);
  -o-transform: translateY(-5px) translateZ(0);
  transform: translateY(-5px) translateZ(0);
}
.categories_grid a:hover p {
  opacity: 0.8;
  transition-delay: 0.2s;
  -webkit-transform: translateY(0) translateZ(0);
  -moz-transform: translateY(0) translateZ(0);
  -ms-transform: translateY(0) translateZ(0);
  -o-transform: translateY(0) translateZ(0);
  transform: translateY(0) translateZ(0);
}

/* Home video background*/
.header-video {
  position: relative;
  overflow: hidden;
  background-color: lightgray;
  height: 600px !important;
}
@media (max-width: 991px) {
  .header-video {
    height: 480px !important;
  }
}

#hero_video {
  position: relative;
  background-size: cover;
  color: #fff;
  width: 100%;
  height: 100%;
  z-index: 99;
}

video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#hero_video iframe {
  height: 100%;
  width: 100%;
}

video {
  width: 100%;
}

.teaser-video {
  width: 100%;
  height: auto;
}

.header-video--media {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) {
  #hero_video {
    background: lightgray url(../img/slides/slide_home_1.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
  }

  .header-video {
    background: none;
  }
}
#icon_drag_mobile {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: url(../img/drag_icon.svg) 0 0 no-repeat;
  width: 50px;
  height: 30px;
  opacity: 0.6;
  z-index: 99;
  display: none;
}
@media (max-width: 767px) {
  #icon_drag_mobile {
    display: block;
  }
}

.isotope_filter ul {
  list-style: none;
  text-align: center;
  margin: 0 0 30px 0;
  padding: 0;
}
.isotope_filter ul li {
  text-align: center;
  display: inline-block;
  border-right: 1px solid #dddddd;
  line-height: 1;
  padding-right: 13px;
  padding-left: 10px;
}
.isotope_filter ul li:last-child {
  border-right: none;
}
.isotope_filter ul li a {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
}
.isotope_filter ul li a.selected {
  color: #444;
}

.featured {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 400px;
  color: #fff;
  font-size: 18px;
  font-size: 1.125rem;
  overflow: hidden;
}
.featured h3, .featured p, .featured .feat_text_block {
  -webkit-transform: translateX(20px);
  -moz-transform: translateX(20px);
  -ms-transform: translateX(20px);
  -o-transform: translateX(20px);
  transform: translateX(20px);
  opacity: 0;
  visibility: hidden;
  -moz-transition: all 0.05s ease;
  -o-transition: all 0.05s ease;
  -webkit-transition: all 0.05s ease;
  -ms-transition: all 0.05s ease;
  transition: all 0.05s ease;
}
.featured .animated h3, .featured .animated p, .featured .animated .feat_text_block {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  opacity: 1;
  visibility: visible;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.featured .animated h3 {
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
}
.featured .animated p {
  transition-delay: 0.35s;
  -webkit-transition-delay: 0.35s;
  -moz-transition-delay: 0.35s;
  -o-transition-delay: 0.35s;
}
.featured .animated .feat_text_block {
  transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
}
.featured h3 {
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  line-height: 1;
  font-size: 36px;
  font-size: 2.25rem;
}
.featured h3.animated {
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
}
.featured .price_box {
  display: inline-block;
  margin-right: 15px;
  float: left;
}
.featured .price_box .new_price {
  font-size: 32px;
  font-size: 2rem;
  color: #fff;
}
.featured .price_box .old_price {
  font-size: 18px;
  font-size: 1.125rem;
}

a.box_news {
  position: relative;
  display: block;
  padding-left: 230px;
  color: #444;
  margin-bottom: 30px;
  min-height: 150px;
}
@media (max-width: 575px) {
  a.box_news {
    min-height: inherit;
    padding-left: 0;
  }
}
a.box_news figure {
  width: 200px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 575px) {
  a.box_news figure {
    position: relative;
    width: auto;
    height: 200px;
  }
}
a.box_news figure img {
  width: 250px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
  -moz-transform: translate(-50%, -50%) scale(1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1);
  -o-transform: translate(-50%, -50%) scale(1.1);
  transform: translate(-50%, -50%) scale(1.1);
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media (max-width: 575px) {
  a.box_news figure img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}
a.box_news figure figcaption {
  background-color: $clr-secondary;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  color: #fff;
  width: 60px;
  height: 60px;
  padding: 15px;
  line-height: 1;
}
a.box_news figure figcaption strong {
  font-weight: 900;
  display: block;
  font-size: 21px;
  font-size: 1.3125rem;
}
a.box_news h4 {
  font-size: 18px;
  font-size: 1.125rem;
  margin-bottom: 10px;
}
@media (max-width: 575px) {
  a.box_news h4 {
    margin-bottom: 5px;
  }
}
a.box_news:hover img {
  -webkit-transform: translate(-50%, -50%) scale(1);
  -moz-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  -o-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
a.box_news:hover h4 {
  color: #004dda;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
a.box_news ul {
  margin: 0;
  padding: 10px 0 0 0;
}
@media (max-width: 575px) {
  a.box_news ul {
    padding: 0;
  }
}
a.box_news ul li {
  display: inline-block;
  font-weight: 500;
  color: #999;
  font-size: 12px;
  font-size: 0.75rem;
  margin-right: 5px;
}
a.box_news ul li:last-child {
  margin-right: 0;
}
a.box_news ul li:last-child:after {
  content: '';
  margin: 0;
}
a.box_news ul li:after {
  content: '-';
  margin: 0 0 0 10px;
}
